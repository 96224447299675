<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <!-- 基本信息 -->
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-input v-model="form.careUnitName" placeholder="请输入" maxlength="30" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  placeholder="请选择"
                  v-model="form.bidId"
                  :options="bidOptions"
                  @change="handleBidChage"
                  clearable
                  disabled
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="备注">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  :autosize="{minRows:4}"
                  placeholder="请输入"
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="培训内容资料">
                <ayl-upload-files
                  v-model="resourceKey"
                  :limit="3"
                  :maxSize="10240"
                  :accept="''"
                  disabled
                ></ayl-upload-files>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button v-if="0" type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import addForDialog from "@/mixins/add-for-dialog";
export default {
  mixins: [addForDialog],
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      nav: [{ name: "应急预案详情" }],
      submitLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        remark: null, //备注
        resourceKey: [] //上传培训内容资料
      },
      resourceKey: [],
      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "请输入养护企业",
          trigger: "blur"
        },
        bidId: [
          {
            required: true,
            message: "请输入标段",
            trigger: "change"
          }
        ]
      }
    };
  },

  methods: {
    async submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.resourceKey = this.resourceKey.map(item => {
        return JSON.stringify(item);
      });
      await this.$api_hw
        .operationSafetyManagement_emergencyPlanUpdate({
          record: formData
        })
        .then(res => {
          BUS.$emit(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT1);
        });
    }
  },
  async mounted() {
    // 获取详情
    await this.$api_hw
      .operationSafetyManagement_emergencyPlanGetDetail({
        id: this.dataFromDialog.id
      })
      .then(res => {
        this.form = res ? res : this.form;
      });
    this.resourceKey = this.form.resourceKey
      ? this.form.resourceKey.map(item => {
          return JSON.parse(item);
        })
      : [];
  }
};
</script>

<style lang='sass' scoped>
.content-box .content-main
  padding: 0 20px 20px
.content-box 
  padding: 0
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-textarea.is-disabled .el-textarea__inner
  color: #526a75
</style>